<template>
  <div class="app-body">
    <span class="a-fs-16 a-ptb-15 a-fw-700">待办事项</span>
    <div class="todolist a-flex-rsbfs a-fs-14">
      <el-card class="todolist-left a-flex-cfsfs">
        <div
          v-for="item in todoTypelist"
          :key="item.key"
          class="todolist-left-item"
          :class="{ active: pageParam.params.keyType == item.key }"
          @click="pageParam.params.keyType = item.key"
        >
          {{ item.title }} ({{ item.num }})
        </div>
      </el-card>
      <el-card class="todolist-right a-ml-16 el-main">
        <el-form>
          <el-form-item label="处理状态">
            <el-select v-model="pageParam.params.isDeal" placeholder="请选择状态">
              <el-option label="已处理" :value="1"></el-option>
              <el-option label="待处理" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <le-pagview :page-param="pageParam" @setData="setTableData">
          <el-table
            v-sticky="{ top: 0, parent: '.todolist-right .el-card__body' }"
            :data="tableData"
            :highlight-current-row="true"
            style="width: 100%"
          >
            <el-table-column prop="title" label="事项名称" width="200"></el-table-column>
            <el-table-column prop="content" label="内容" width="600"></el-table-column>
            <el-table-column prop="opUserName" label="提交人"></el-table-column>
            <el-table-column prop="createTimeText" label="提交时间" width="200"></el-table-column>
            <el-table-column label="状态" width="150">
              <template slot-scope="{ row }">
                <el-tag>{{ row.status }}</el-tag>
              </template>
            </el-table-column>

            <el-table-column v-if="!pageParam.params.isDeal" label="操作" fixed="right">
              <template slot-scope="{ row }">
                <el-tooltip class="item" effect="dark" content="去处理" placement="top">
                  <div
                    v-if="row.pcNotifyPage"
                    class="a-flex-rcc a-w-100"
                    @click="handlerGoDetail(row)"
                  >
                    <span
                      class="a-c-blue a-w-100"
                      style="text-align: center"
                      :class="row.pcNotifyPage ? 'a-cursor-p' : 'a-default-p'"
                    >
                      去处理
                    </span>
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </le-pagview>
      </el-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TodolistIndex",

  data() {
    return {
      pageParam: {
        url: this.$Config.apiUrl.getWithDoneList,
        method: "post",
        params: {
          keyType: "",
          isDeal: 0,
        },
        freshCtrl: 1,
      },
      tableData: [],
    };
  },
  computed: {
    ...mapGetters("todonum", ["todoTypelist"]),
  },
  watch: {
    "pageParam.params": {
      handler() {
        this.pageParam.freshCtrl++;
      },
      deep: true,
    },
  },
  methods: {
    setTableData(data) {
      this.tableData = data;
    },
    handlerSearch() {
      this.pageParam.freshCtrl++;
    },
    handlerGoDetail(obj) {
      if (obj.pcNotifyPage && obj.pcNotifyPageParam) {
        this.$router.push({
          path: obj.pcNotifyPage,
          query: JSON.parse(obj.pcNotifyPageParam),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.todolist {
  height: 100%;

  &-left {
    height: calc(100% - 80px);

    /deep/ .el-card__body {
      padding: 0;
      width: 100%;
      padding-bottom: 50px;
    }

    width: 184px;
    padding: 16px 8px;

    &-item {
      height: 40px;
      line-height: 40px;
      padding-left: 16px;
      border-radius: 4px;
      width: 100%;

      &:hover {
        font-weight: bold;
        // background: #EBF0F5;
      }

      &.active {
        font-weight: bold;
        background: #ebf0f5;
      }
    }
  }

  &-right {
    height: calc(100% - 80px);
  }
}

/deep/ .searchToo {
  display: none;
}
</style>
